import React from 'react';
import { graphql } from 'gatsby';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Layout from '../components/layout';
import TeamMember from '../components/team/team-member';
import PropTypes from 'prop-types';

const styling = (theme) => ({
  about: {
    margin: 'auto',
    marginTop: 16,
    maxWidth: 600,
    padding: 16,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  contentGrid: {
    margin: 'auto',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1050,
    },
  },
  header: {
    marginBottom: -32,
    paddingTop: 16,
    textAlign: 'center',
  },
});

const TeamPage = ({ data }) => {
  // Styling/Theming
  const classes = makeStyles(styling)();
  const teamMemberPhotos = data.allFile.nodes;

  const getTeamMemberPhoto = member => {
    const photo = teamMemberPhotos.find(node => node.relativePath === member.src);
    if (!photo) return null;
    return photo.publicURL;
  }
  
  return (
    <Layout title="Team">
      <Typography className={classes.header} variant="h1">Our Team</Typography>
      <Box className={classes.about}>
        <Typography variant="body1">
          Blue Rondo Games is a company born out of a shared love for
          games - the spark of collaborative creativity that comes with making
          them and the joy of bringing people together that comes with
          playing them.
        </Typography>
      </Box>
      <Box className={classes.contentGrid} sx={{ flexGrow: 1 }}>
        <Grid container>
          {data.allTeamListJson.nodes.map((member, idx) => (
            <Grid item={true} key={idx} xs={12}>
              {member.active &&
                <TeamMember
                  bio={member.bio}
                  description={member.description}
                  name={member.name}
                  src={getTeamMemberPhoto(member)}
                  title={member.title}
                />
              }
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
}

TeamPage.propTypes = {
  data: PropTypes.any.isRequired,
}

export const query = graphql`
  query TeamMemberQuery{
    allTeamListJson {
      nodes {
        bio
        description
        name
        src
        title
        active
      }
    }
    allFile(filter: {sourceInstanceName: {eq: "team-member-photos"}}) {
      nodes {
        relativePath
        publicURL
      }
    }
  }
`

export default TeamPage
