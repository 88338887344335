import React from 'react';
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const styling = (theme) => ({
  description: {
    fontStyle: 'italic',
  },
  image: {
    marginLeft: 'auto',
    height: 250,
    [theme.breakpoints.up('md')]: {
      maxWidth: 350,
    }
  },
  name: {
    alignContent: 'flex-end',
  },
  root: {
    paddingTop: 16,
  },
  title: {
  },
});

const TeamMember = ({ bio, description, name, src, title }) => {
  // Styling
  const theme = useTheme();
  const classes = makeStyles(styling)();

  // Hooks
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // md = 960px

  return (
    <Card
      className={classes.root}
      elevation={0}
      square
      style={{ backgroundColor: 'transparent' }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid container direction="column" item xs={5} md={3}>
            <Grid item>
              <CardMedia
                className={classes.image}
                component={'img'}
                src={src}
              />
            </Grid>
          </Grid>

          <Grid item xs={1} />

          <Grid container direction="column" justifyContent="center" item xs={6} md={3} lg={4}>
            <Grid item>
              <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.name} variant="h4">
                {name}
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </Grid>
          </Grid>

          { isDesktop &&
            <>
              <Grid item xs={false} md={1} />

              <Grid alignItems="center" container item xs={false} md={4} lg={3}>
                <Grid item>
                  <Typography sx={{ display: { xs: "none", md: "block" }}}>
                    {bio}
                  </Typography>
                </Grid>
              </Grid>
            </>
          }
        </Grid>
      </Box>
    </Card>
)};

TeamMember.propTypes = {
  bio: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default TeamMember;
